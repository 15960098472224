import { useContext, useEffect, useState } from 'react';
import { BiCartAdd } from 'react-icons/bi';

import { Button } from '@components/Button';
import { AddToCart } from '@components/Button/AddToCart';
import { AddToCartStatus } from '@components/Button/AddToCart';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { Modal } from '@components/Modal/Modal';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { Variant } from '@interfaces/Product';

export const FlavourDescriptions = ({ variants }: { variants: Variant[] }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    addingToCart: [addingToCartStatus, setAddingToCartStatus],
  } = useContext<UIContextInterface>(UIContext);

  useEffect(() => {
    /* 
      Have to set the status to None when closed otherwise it stays on 'Added'
      causing the green banner to appear everytime the modal reopens
      if a product was previously added
    */
    if (!showModal) setAddingToCartStatus(AddToCartStatus.None);
  }, [showModal]);

  return (
    <>
      <Button 
        onClick={(e) => setShowModal(true)} 
        buttonStyle="text" 
        data-umami-event="hue-flavour-description-button-clicked" 
        data-umami-event-product-id={variants[0].productId}
      >
        What's this flavour like?
      </Button>
      <Modal
        show={showModal}
        setShow={setShowModal}
        className="max-h-[80vh] justify-normal overflow-y-scroll pt-12 dark:bg-black"
      >
        <div
          data-addedtocart={addingToCartStatus === AddToCartStatus.Added}
          className="absolute right-[50px] top-2.5 bg-green p-2 font-bold italic text-white 
          opacity-0 data-[addedtocart=true]:animate-[addToCartConfirmation_5s_0.1s_forwards]"
        >
          <p className="m-0 text-xl font-bold italic">Added to your cart</p>
        </div>
        {variants.map((v) => (
          <div className="align-left mb-2 mt-6 w-full" key={v.options[1].label}>
            <div className="flex flex-row justify-between">
              <h3 className="text-4xl font-bold italic text-black dark:text-white">
                {v.options[1].label}
              </h3>
              <AddToCart
                variantId={v.variantId}
                productId={v.productId}
                quantity={1}
                triggerCartModal={false}
                customIcon={<BiCartAdd size="2rem" />}
                tooltipText="Quick add to cart"
                data-umami-event="hue-add-to-cart-clicked"
                data-umami-event-product-id={v.productId}
                data-umami-event-variant-id={v.variantId}
                data-umami-event-location="what's this flavour like modal"
                className="bg-transparent text-grey-darkest hover:scale-110 hover:bg-transparent active:text-orange disabled:bg-transparent
                  dark:text-white dark:hover:scale-110 active:dark:text-orange disabled:dark:bg-transparent sm:hover:text-orange
                  sm:hover:dark:text-orange"
              />
            </div>
            <Paragraph className="mt-0">{v.flavourDescription}</Paragraph>
          </div>
        ))}
      </Modal>
    </>
  );
};
